<template>
  <Layout>
    <div class="app-template">
      <div class="list">
        <div @click="add(0)" class="item new-item">
          <div class="content">
            <div class="icn">
              <a-icon type="plus" class="icon" />
            </div>
            <div class="desc">新建页面</div>
          </div>
        </div>
        <div
          v-for="t in list"
          :key="t.id"
          @click="add(t.id)"
          class="item template-item"
        >
          <div class="content">
            <img class="cover" :src="t.img" />
            <div class="block">
              <div class="title">{{ t.name }}</div>
              <div class="btn">选用</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/layout/index.vue";

const TemplateSvc = require("@/service/template");
const PageSvc = require("@/service/page");

export default {
  name: "Template",

  data() {
    return {
      list: []
    };
  },

  methods: {
    async add(templateId) {
      try {
        const { id } = await PageSvc.add(templateId);

        this.$router.push({ path: `/page/detail/${id}` });
      } catch (err) {
        console.error(err);
        this.$message.error("添加页面失败，错误：" + err.message);
      }
    }
  },

  async mounted() {
    try {
      const list = await TemplateSvc.list();
      this.list = list;
    } catch (err) {
      console.error(err);
      this.$message.error("未能正确加载模板，错误：" + err.message);
    }
  },

  components: {
    Layout
  }
};
</script>

<style lang="scss">
.app-template {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--header-height));
  background: #f0f2f5;
  padding: 24px 24px 0;

  .list {
    width: 95.2rem;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;

    .item {
      width: 220px;
      height: 336px;
      margin-right: 24px;
      margin-bottom: 28px;
      padding: 10px;
      border-radius: 8px;
      border: 1px solid rgba(0, 0, 0, 0.04);
      background-color: #fff;
      transition: opacity 0.25s ease;
      position: relative;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }

    .new-item {
      .content {
        border-radius: 6px;
        border: 2px dashed rgba(0, 0, 0, 0.1);
        height: 100%;
        flex-direction: column;
      }

      .content,
      .content .icn {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .content .icn {
        width: 40px;
        height: 40px;
        border: 2px solid hsla(0, 0%, 42%, 0.8);

        border-radius: 50%;

        .icon {
          color: hsla(0, 0%, 42%, 0.8);
          font-size: 24px;
        }
      }

      .content .desc {
        font-size: 14px;
        line-height: 1;
        color: rgba(0, 0, 0, 0.66);
        margin-top: 20px;
      }
    }

    .template-item .content {
      height: 100%;
      display: flex;
      flex-direction: column;

      .cover {
        flex: 1 1 auto;
        width: 100%;
        border: none;
      }

      .block {
        flex: 0 0 auto;
        height: 54px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.66);
        }

        .btn {
          width: 48px;
          height: 24px;
          border-radius: 4px;
          background-color: #333;
          text-align: center;
          line-height: 24px;
          font-size: 12px;
          color: #fff;
        }
      }
    }
  }

  @media screen and (max-width: 1280px) {
    .list .item:nth-child(4n) {
      margin-right: 24px;
    }

    .list .item:nth-child(3n) {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 952px) {
    .list .item:nth-child(3n),
    .list .item:nth-child(4n) {
      margin-right: 24px;
    }

    .list .item:nth-child(2n) {
      margin-right: 0;
    }
  }
}
</style>
